import { randomId } from "@/lib/random";
import { randomHexString } from "@/lib/stringUtil";
import { getLogger } from "@/log";
import {
  OpenToggle,
  Editor,
  ReactionUI,
  StoreTy,
  VerifyUI,
  NotificationMessage,
  PersonaFilter,
  PersonaSelectorUpdate,
  AnonymousAccount,
  Layout,
  FilterToolbar,
  EventEditor,
  ScrollState,
} from "@/store/ty";

const logger = getLogger(__filename);

const uiTrigger = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => ({
  /** <require an account> */
  editorToggle: editorToggle(updateStream, stateStream),
  eventEditorToggle: eventEditorToggle(updateStream, stateStream),
  reactionUIToggle: reactionUIToggle(updateStream, stateStream),
  verifyUIToggle: verifyUIToggle(updateStream, stateStream),
  /** </require an account> */
  anonymouseAccount: anonymouseAccount(updateStream, stateStream),
  personaFilter: personaFilter(updateStream, stateStream),
  filterToolbar: filterToolbar(updateStream, stateStream),
  convoLayout: convoLayout(updateStream, stateStream),
  accountPrompt: accountPrompt(updateStream, stateStream),
  personaPrompt: personaPrompt(updateStream, stateStream),
  notificationMessage: notificationMessage(updateStream, stateStream),
  notificationMessageDismissal: notificationMessageDismissal(
    updateStream,
    stateStream
  ),
  personaSelectorUpdate: personaSelectorUpdate(updateStream, stateStream),
  searchConversationsUpdate: searchConversationsUpdate(
    updateStream,
    stateStream
  ),
  scrollContainerStateUpdate: scrollContainerStateUpdate(
    updateStream,
    stateStream
  ),

  notification: {
    info: (message: string) =>
      emitNotification(updateStream, stateStream)({ state: "info", message }),
    ok: (message: string) =>
      emitNotification(updateStream, stateStream)({ state: "ok", message }),
    warn: (message: string) =>
      emitNotification(
        updateStream,
        stateStream
      )({ state: "warning", message }),
    error: (message: string) =>
      emitNotification(updateStream, stateStream)({ state: "error", message }),
    networkErr: () =>
      emitNotification(
        updateStream,
        stateStream
      )({ state: "error", message: "Error. Please try again later" }),
  },
});

const reactionUIToggle = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (reactionUI: ReactionUI) => {
  return updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        reactionUI: {
          ...reactionUI,
        },
      },
    })
  )();
};
const editorToggle = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (editor: Editor) => {
  const oldEditorState = stateStream().ui.editor;
  // NOTE `eventEditor` is mutually exclusive with `editor`
  const oldEventEditor = stateStream().ui.eventEditor;

  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        editor: {
          ...oldEditorState,
          ...editor,
          // <old state to discard>
          eventId: editor.eventId || undefined,
          parentConvo: editor.parentConvo || undefined,
          // </old state to discard>
        },
        eventEditor: {
          ...oldEventEditor,
          modalVisible: false,
        },
      },
    })
  );
};
const eventEditorToggle = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (eventEditor: EventEditor) => {
  console.log("GOT", eventEditor);
  // NOTE `eventEditor` is mutually exclusive with `editor`
  const oldEventEditor = stateStream().ui.eventEditor;
  const oldEditor = stateStream().ui.editor;

  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        editor: {
          ...oldEditor,
          isOpen: false, // TODO: rm once we migrate
          modalVisible: false,
        },
        eventEditor: {
          ...oldEventEditor,
          ...eventEditor,
        },
      },
    })
  );
};
const verifyUIToggle = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (verifyUI: VerifyUI) => {
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        verifyUI: verifyUI,
      },
    })
  );
};
const filterToolbar = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (newState: FilterToolbar) => {
  const oldState = stateStream().ui.filterToolbar;

  logger.info("updating here:: " + JSON.stringify(newState));
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        filterToolbar: {
          geoFilter: {
            ...(oldState?.geoFilter ?? {}),
            ...(newState.geoFilter ?? {}),
          },
          personaFilter: {
            ...(oldState?.personaFilter ?? {}),
            ...(newState.personaFilter ?? {}),
          },
          timeFilter: {
            ...(oldState?.timeFilter ?? {}),
            ...(newState.timeFilter ?? {}),
          },
        },
      },
    })
  );
};
const personaFilter = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (newState: PersonaFilter) => {
  const oldState = stateStream().ui.personaFilter;

  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        personaFilter: {
          ...oldState,
          ...newState,
        },
      },
    })
  );
};

const convoLayout = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (newState: { layout: Layout }) => {
  const oldState = stateStream().ui.convoLayout;

  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        convoLayout: newState.layout,
      },
    })
  );
};

const notificationMessageDismissal = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (msgId: string) => {
  const oldState = stateStream().ui?.notification;
  const msgs = oldState?.messages ?? [];

  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        notification: {
          ...oldState,
          messages: msgs.filter((msg) => msg.id !== msgId),
        },
      },
    })
  );
};

const notificationMessage = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (msg: NotificationMessage) => {
  const oldState = stateStream().ui?.notification;
  const msgs = oldState?.messages ?? [];

  msg.state = msg.state ?? "ok";
  msg.id = randomId();

  return updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        notification: {
          ...oldState,
          messages: msg ? msgs.concat(msg) : msgs,
        },
      },
    })
  );
};

const accountPrompt = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (msg?: OpenToggle) => {
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        accountPromptVisible: msg?.isOpen ?? true,
      },
    })
  );
};

const personaPrompt = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (msg?: OpenToggle) => {
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        personaPromptVisible: msg?.isOpen ?? true,
      },
    })
  );
};

const personaSelectorUpdate = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (msg?: PersonaSelectorUpdate) => {
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        defaultPersona: msg?.default,
        selectedPersona: msg?.selected,
      },
    })
  );
};

const searchConversationsUpdate = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (state: Array<string>, convos: any) => {
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        searchConversations: state,
        conversations: convos,
      },
    })
  );
};

const scrollContainerStateUpdate = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (scrollState?: ScrollState) => {
  updateStream(
    Promise.resolve({
      ui: {
        ...stateStream().ui,
        scrollContainerState: scrollState,
      },
    })
  );
};

const anonymouseAccount = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (msg?: AnonymousAccount) => {
  const uiState = stateStream().ui;
  const oldState = uiState.anonymouseAccount;

  updateStream(
    Promise.resolve({
      ui: {
        ...uiState,
        anonymouseAccount: {
          ...oldState,
          ...(msg || {}),
        },
      },
    })
  );
};

// // TODO: useSWR allows UI component to make local choice to choose the right
// // ui trigger
// const requireAccount = (
//   updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
//   stateStream: flyd.Stream<StoreTy>,
//   fn: (...args: Array<any>) => void
// ) => (...args: Array<any>) => {
//   const { userAccount } = stateStream();

//   if (userAccount?.authToken) {
//     return fn(...args);
//   }

//   accountPrompt(updateStream, stateStream)();
// };

const emitNotification = (
  updateStream: flyd.Stream<Promise<Partial<StoreTy>>>,
  stateStream: flyd.Stream<StoreTy>
) => (n: Omit<NotificationMessage, "id">) => {
  const oldState = stateStream().ui?.notification;
  const msgs = oldState?.messages ?? [];

  const uiState = stateStream().ui;
  const msg = {
    ...n,
    id: randomHexString(),
  };

  return updateStream(
    Promise.resolve({
      ui: {
        ...uiState,
        notification: {
          ...oldState,
          messages: msg ? msgs.concat(msg) : msgs,
        },
      },
    })
  );
};

export default uiTrigger;
