export const TIME_FILTER_INTERVALS = [
  "any_time",
  "past_day",
  "past_week",
  "past_month",
];

export function timeFilter2RAfter(tf: string): string {
  if (tf === "past_day") {
    return "-24h";
  } else if (tf === "past_week") {
    return "-168h";
  } else if (tf === "past_month") {
    return "-720h";
  }
  return "";
}

export function validateTimeFilterQueryParam(s: string): string {
  const idx = TIME_FILTER_INTERVALS.indexOf(s);
  if (idx === -1) {
    return "";
  }
  return s;
}
