import type { AppProps /*, AppContext */ } from "next/app";
import { Router, useRouter } from "next/router";

import I18nProvider from "@/i18n";
import NextNProgress from "nextjs-progressbar";

import Loading from "@/comp/Loading";
import useSWRSession from "@/hook/useSWRSession";
import { SessionContext } from "@/nextlib/context";
import { SWRConfig } from "swr";

import { trigger } from "@/store/store";
import { useCallback, useEffect, useRef } from "react";
import "./global.scss";

// load locales
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "time-ago": any;
    }
  }
}

export default function App({ Component, pageProps }: AppProps) {
  const { lngDict } = pageProps as any;

  const router = useRouter();
  const { session, isLoading } = useSWRSession();
  const scrollContainerRef = useRef(null);

  const scrollFix = () => {
    setTimeout(() => {
      document.querySelector("#scroll-container")?.scrollTo({
        top: 0,
      });
    }, 0);
  };

  useEffect(() => {
    Router.events.on("routeChangeComplete", scrollFix);
    // console.log("HUH", scrollContainerRef);
    // if (scrollContainerRef.current) {
    //   const cur = scrollContainerRef.current as any;
    //   console.log("HEY", cur);
    //   cur.scrollTop = 0;
    // }
    return () => {
      Router.events.off("routeChangeComplete", scrollFix);
    };
  }, []);

  // Function to handle scroll events
  const handleScroll = () => {
    const container = scrollContainerRef.current as any;
    if (container) {
      trigger.ui.scrollContainerStateUpdate({
        reachedBottom:
          container.scrollHeight <=
          container.scrollTop + container.clientHeight,
        scrollHeight: container.scrollHeight,
        scrollTop: container.scrollTop,
      });
    }
  };

  // Add scroll event listener
  useEffect(() => {
    const container = scrollContainerRef.current as any;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      // return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [scrollContainerRef, handleScroll]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <I18nProvider lngDict={lngDict} locale={router.locale!}>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          onErrorRetry(error, key, config, revalidate, { retryCount = 0 }) {
            // Never retry on 404.
            // TODO: error.status === 401 <- should not retry but
            // something wrong with auth
            if (error.status === 404 || error.status === 401) {
              return;
            }
            // configure max retry
            if (retryCount >= 3) {
              return;
            }
            // Retry after 5 seconds.
            setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
          },
        }}
      >
        <NextNProgress height={5} />
        <SessionContext.Provider value={session}>
          <div
            id="scroll-container"
            ref={scrollContainerRef}
            className="x-_app h-100v w-full overflow-x-hidden sm:w-auto bg-gray-100 "
          >
            <Component {...pageProps} />
          </div>
        </SessionContext.Provider>
      </SWRConfig>
    </I18nProvider>
  );
}
